import React, { FC } from 'react';
import { Link } from 'gatsby';

import { SitemapCategoryItemProps } from './models';

const SitemapCategoryItem: FC<SitemapCategoryItemProps> = ({ name, url, subLinks, seoProps }) => {
  const linkName = seoProps ? seoProps.seoMetaTitle : name;

  return (
    <li className="dt-sitemap-category__item" data-testid="sitemap-subcategory">
      <Link to={url} aria-label={linkName}>
        {linkName}
      </Link>
      {subLinks ? (
        <ul className="dt-sitemap-category__list">
          {subLinks.map((item) => (
            <SitemapCategoryItem {...item} key={item.id} />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default SitemapCategoryItem;
