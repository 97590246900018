import React, { FC } from 'react';
import { Link } from 'gatsby';

import { SitemapCategoryProps } from './models';
import SitemapCategoryItem from './SitemapCategoryItem';

const SitemapCategory: FC<SitemapCategoryProps> = ({ name, url, subLinks, seoProps }) => {
  const linkName = seoProps ? seoProps.seoMetaTitle : name;

  return (
    <li className="sitemap-category" data-testid="sitemap-category">
      <Link to={url} aria-label={linkName}>
        {linkName}
      </Link>
      {subLinks?.length && (
        <ul>
          {subLinks?.map((item) => (
            <SitemapCategoryItem key={item.id} {...item} />
          ))}
        </ul>
      )}
    </li>
  );
};

export default SitemapCategory;
